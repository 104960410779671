@import "../node_modules/primeflex/primeflex.scss";

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

// body .p-inputtext {
//   width: 324px;
// }

.navItemList {
  display: flex;
}

.p-card {
  margin-top: 25px;
}

.p-card-title {
  size: 24px !important;
  margin-bottom: 18px;
}

.p-grid .p-dropdown {
  width: 100%;
}

.preview-label {
  font-size: 16px;
  font-weight: bold;
}

.preview-card {
  padding: 20px;
}

.errorText {
  color: red;
}

// .p-datatable-header {
//   min-width: 1105px;
// }

// .p-datatable-wrapper {
//   min-width: 1105px;
// }

.p-datatable {
  td {
    overflow-wrap: break-word;
  }
}

.required {
  color: red;
  font-weight: bold;
  margin-left: 8px;
}

.field-label {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 6px;
}

.nav {
  background-color: #ffffff;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

li.nav-item {
  padding-right: 10px;
}

body {
  background-color: #97979738;
  background-image: url("https://www.transparenttextures.com/patterns/stacked-circles.png");
  padding-top: 144px;
  padding-bottom: 120px;
  
}

.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 999;
}

.fixed-footer {
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: white;
}
.fixed-header {
  top: 0;
}
.fixed-footer {
  bottom: 0;
  height: 40px;
  color: grey !important;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.25);
  border-top: 1px black;
  .nav-link {
    color: grey !important;
  }
}
.container {
}

.noExpander .p-row-toggler {
  display: none;
}

.payoff-table tr:last-child {
  font-weight: bold;
}

.p-filter-column,
.full-width-input {
  .p-inputtext {
    width: 100%;
  }
}

.place-card.place-card-large {
  display: none !important;
}

.p-card .card-full-width {
  //width: 100%;
  // max-width: 100% !important;
  // margin-left: auto;
  // margin-right: auto;
}

.p-tabview-title {
  margin-left: 6px;
}

.p-toast-top-right {
  top: 130px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  body {
    padding-top: 0;
    padding-bottom: 0;
  }

  a[href]:after {
    content: none !important;
  }
  img[src]:after {
    content: none !important;
  }

  .bottom-bar {
    display: none;
  }

  #print-button{
    display: none;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

// .sticky-legal,
// .p-datatable-thead {
//   position: -webkit-sticky; /* Safari & IE */
//   position: sticky;
//   top: 135px;
//   z-index: 999;
// }

.related-parcels {
  .p-button {
    margin-bottom: 10px;
  }
}

.parcel-list .p-selectable-row {
  height: 90px;
}

span.p-paginator-current {
  width: 320px;
}

.pi.pi-exclamation-triangle {
  color: orange;
  height: 30px;
  width: 30px;
}

span.p-confirm-dialog-message {
  max-width: 25vw;
}

.red-text {
  .p-card-title {
    color: red;
    font-size: 40px;
  }
  .p-card-content {
    font-weight: bold;
  }
}

.green-text {
  color: green;
}

.p-tag.Redeemed{
  background-color: red !important;
}

.p-tag.Legal{
  background-color: blue !important;
  color: white !important;
}

.p-tag.Bankruptcy{
  background-color: yellow !important;
  color: black !important;
}

.p-tag.REO{
  background-color: green !important;
}

.p-tag.Active.Certificate{
  background-color: orange !important;
}



@media screen and (max-width: $sm) {
  .card {
    //color: pink;
    select,
  textarea,
  input {
    font-size: 16px;
  }
  }
}


@media screen and (-webkit-min-device-pixel-ratio:0px) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

